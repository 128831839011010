import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Blank, Select as CommonSelect, Label } from 'src/components/common';
import { useStudentTestScore } from 'src/container/student-semesters-score';
import HintMessage from '../common/HintMessage';

interface MockChartProps {
  studentId: string;
}

interface ChartData {
  [scoreType: string]: {
    [month: string]: Array<{
      subject_group: string;
      sub_subject: string | null;
      optional_subject: string | null;
      [key: string]: number | string | null;
    }>;
  };
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const SCORE_FILTER = [
  { label: '등급', value: 'rank' },
  { label: '표준점수', value: 'standard_score' },
  { label: '백분위', value: 'percentile' },
];

const colors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
];

const animatedComponents = makeAnimated();

export default function MockChart({ studentId }: MockChartProps) {
  const [scoreType, setScoreType] = useState<string>(SCORE_FILTER[0].value);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData>({});
  const { scores, isLoading } = useStudentTestScore(Number(studentId), new Date().getFullYear());

  useEffect(() => {
    if (scores?.length) {
      const groupedByScoreType: ChartData = SCORE_FILTER.reduce((acc, { value }) => {
        acc[value] = {};
        return acc;
      }, {} as ChartData);

      scores[0].scores.forEach((score: any) => {
        SCORE_FILTER.forEach(({ value: scoreType }) => {
          const monthKey = `${score.month}월`;
          const scoreData = {
            subject_group: score.subject,
            sub_subject: score.sub_subject,
            optional_subject: score.optional_subject,
            [scoreType]: score[scoreType] || null,
          };

          if (!groupedByScoreType[scoreType][monthKey]) {
            groupedByScoreType[scoreType][monthKey] = [];
          }
          groupedByScoreType[scoreType][monthKey].push(scoreData);
        });
      });

      SCORE_FILTER.forEach(({ value: scoreType }) => {
        const subjectAverages: { [key: string]: { total: number; count: number } } = {};

        Object.keys(groupedByScoreType[scoreType]).forEach((monthKey) => {
          groupedByScoreType[scoreType][monthKey].forEach((data: any) => {
            const subjectGroup = data.subject_group;
            if (!subjectAverages[subjectGroup]) {
              subjectAverages[subjectGroup] = { total: 0, count: 0 };
            }
            const scoreValue = parseFloat(data[scoreType]) || null;

            if (scoreValue !== null) {
              subjectAverages[subjectGroup].total += scoreValue;
              subjectAverages[subjectGroup].count++;
            }
          });
        });

        groupedByScoreType[scoreType]['평균'] = Object.keys(subjectAverages).map((subjectGroup) => {
          const { total, count } = subjectAverages[subjectGroup];
          return {
            subject_group: subjectGroup,
            sub_subject: null, // 평균 데이터에 대해 null로 설정
            optional_subject: null, // 평균 데이터에 대해 null로 설정
            [scoreType]: count > 0 ? (total / count).toFixed(2) : null, // 유효한 값이 있을 경우 평균 계산, 없으면 null
          };
        });
      });

      const uniqueMonths = _.chain(scores[0].scores)
        .map('month')
        .uniq()
        .sortBy()
        .value()
        .map((month) => `${month}월`); // '3월', '6월' 등의 라벨 생성
      setLabels([...uniqueMonths, '12월', '평균']); // 마지막에 '평균' 추가
      setChartData(groupedByScoreType);
    }
  }, [scores]);

  // 과목 이름 추출
  const subjectOptions =
    scores && scores.length > 0 && scores[0].scores
      ? _.chain(scores[0].scores)
          .map((score) => {
            return {
              value: score.subject,
              label: score.subject,
            };
          })
          .uniqBy('value') // 중복 제거
          .value()
      : []; // scores가 없는 경우 빈 배열 반환

  // 차트 옵션 정의
  const options = {
    spanGaps: false,
    responsive: true,
    interaction: {
      mode: 'point' as const,
      intersect: true,
    },
    plugins: {
      legend: {
        position: 'top' as const,
        align: 'end' as const,
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            family: "'Noto Sans KR', 'serif'",
            lineHeight: 1,
          },
          boxWidth: 10,
          boxHeight: 10,
          generateLabels: function (chart: any) {
            return chart.data.datasets.map((dataset: any, i: any) => ({
              text: dataset.label,
              fillStyle: colors[i],
              strokeStyle: colors[i],
              lineWidth: 2,
              pointStyle: 'circle',
              hidden: !chart.isDatasetVisible(i),
              index: i,
            }));
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        borderColor: 'black',
        borderWidth: 1,
        padding: 20,
        bodySpacing: 10,
        usePointStyle: true,
        filter: (item: any) => item.parsed.y !== null,
        callbacks: {
          title: (tooltipItems: any) => {
            const tooltipItem = tooltipItems[0];
            const label = tooltipItem.label; // x축 라벨 (ex: "3월", "6월", "평균")
            const subjectGroup = tooltipItem.dataset.label; // subject_group 값
            const subjectData = chartData[scoreType][label]?.find((data: any) => data.subject_group === subjectGroup);

            if (!subjectData) return `${label} (${subjectGroup})`;

            // optional_subject가 있으면 우선 표시
            const title = subjectData.optional_subject || subjectData.subject_group;

            // 국어, 수학인 경우 sub_subject 우선 표시
            const displayTitle =
              subjectGroup === '국어' || subjectGroup === '수학' ? subjectData.sub_subject || title : title;

            if (label === '평균') return `${displayTitle} - 평균`;
            return `${label} (${displayTitle})`;
          },
          label: (tooltipItem: any) => {
            const currentFilter = SCORE_FILTER.find((filter) => filter.value === scoreType);
            const scoreTypeLabel = currentFilter ? currentFilter.label : '점수';
            return `${scoreTypeLabel}: ${tooltipItem.raw}`;
          },
        },
        titleColor: 'black',
        bodyColor: 'black',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        offset: true,
      },
      y: {
        grid: {
          color: '#DDDDDD',
        },
        axis: 'y' as const,
        display: true,
        max: scoreType === 'rank' ? 9 : scoreType === '원점수' ? 100 : undefined,
        min: scoreType === 'rank' ? 0 : undefined,
        reverse: scoreType === 'rank' ? true : undefined,
        ticks: {
          callback: function (tickValue: string | number) {
            // 기준선의 데이터가 숫자이고, 0일 경우 빈 문자열 아닐 경우에는 값 그대로 전달
            if (typeof tickValue === 'number') {
              return tickValue === 0 ? '' : tickValue;
            }
            return tickValue;
          },
        },
      },
    },
  };

  // 각 Option 들에 대한 스타일 설정
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: state.isFocused ? '#EE853A' : base.borderColor, // 포커스 시 테두리 색상
      boxShadow: state.isFocused ? '0 0 0 1px #EE853A' : base.boxShadow, // 포커스 시 그림자
      '&:hover': {
        borderColor: state.isFocused ? '#EE853A' : base.borderColor,
      },
    }),
    input: (base: any) => ({
      ...base,
      border: 'none !important',
      boxShadow: 'none !important',
      '&:focus': {
        outline: 'none !important',
        boxShadow: 'none !important',
      },
      '&:hover': {
        borderColor: 'none !important',
      },
      '[type="text"]:focus': {
        boxShadow: 'none !important',
      },
    }),
    multiValue: (base: any) => ({
      ...base,
      color: 'white',
      backgroundColor: 'lightpink',
      borderRadius: 5,
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#999',
    }),
  };

  if (isLoading) return <Blank />;
  return (
    <div>
      <div className="my-4 flex w-full flex-col gap-4 md:flex-col">
        <div className="flex flex-row items-center gap-4">
          <Label htmlFor="subject" className="min-w-[150px] font-bold" children="과목 선택 (다중)" />
          <Select
            options={subjectOptions}
            id="subject"
            isMulti
            styles={customStyles}
            className="min-w-100 md:min-w-10"
            components={animatedComponents}
            closeMenuOnSelect={false}
            value={subjectOptions.filter((option) => selectedSubjects.includes(option.value))}
            onChange={(selectedOptions: any) => setSelectedSubjects(selectedOptions.map((option: any) => option.value))}
            placeholder="과목을 선택하세요"
          />
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="flex min-w-[150px] flex-row items-center justify-between">
            <Label htmlFor="scoreType" className="font-bold" children="데이터 선택" />
            <HintMessage message="슈퍼스쿨 성적관리 시스템으로 산출된 예상 성적입니다. 정확한 성적은 반드시, NEIS와 학교 발급 성적표에서 확인하시기 바랍니다." />
          </div>
          <CommonSelect
            value={scoreType}
            onChange={(e) => setScoreType(e.target.value)}
            id="scoreType"
            className="h-10 min-w-10 rounded-[4px]"
          >
            {SCORE_FILTER.map(({ label, value }) => (
              <option value={value} key={label}>
                {label}
              </option>
            ))}
          </CommonSelect>
        </div>
      </div>
      <Chart
        type="line"
        options={options}
        datasetIdKey="id"
        data={{
          labels: labels,
          datasets: selectedSubjects.map((subjectName, index) => {
            return {
              label: subjectName,
              data: labels.map((label) => {
                if (label === '평균') {
                  const averageData = chartData[scoreType]['평균'];
                  const subjectAverage = averageData?.find((average: any) => average.subject_group === subjectName);
                  return subjectAverage ? parseFloat(subjectAverage[scoreType] as string) : null; // null이 아닌 값 처리
                } else {
                  const semesterData = chartData[scoreType][label];
                  const subjectData = semesterData?.find((data: any) => data.subject_group === subjectName);
                  return subjectData ? subjectData[scoreType] : null;
                }
              }),
              borderColor: colors[index % colors.length],
              backgroundColor: colors[index % colors.length],
              borderWidth: 2,
              pointRadius: labels.map((label) => (label === '평균' ? 10 : 8)), // 평균인 경우 반경 설정
              pointHoverRadius: labels.map((label) => (label === '평균' ? 12 : 10)), // hover 시 반경 설정
              pointStyle: labels.map((label) => (label === '평균' ? 'line' : 'circle')), // 평균은 line 스타일
              pointBorderWidth: labels.map((label) => (label === '평균' ? 8 : 2)), // 평균 점 강조
              pointHoverBorderWidth: labels.map((label) => (label === '평균' ? 8 : 2)), // hover 시에도 두께 유지
            };
          }),
        }}
      />
    </div>
  );
}

import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ErrorBlank, FrontPagination, SelectMenus, SuperModal } from 'src/components';
import { BackButton, Blank, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { SearchInput } from 'src/components/common/SearchInput';
import { TextInput } from 'src/components/common/TextInput';
import { Icon } from 'src/components/common/icons';
import { FieldtripResultCard } from 'src/components/fieldtrip/FieldtripResultCard';
import { GroupContainer } from 'src/container/group';
import { useTeacherFieldtripResult } from 'src/container/teacher-fieldtrip-result';
import { UserContainer } from 'src/container/user';
import { Role } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { useSignature } from 'src/hooks/useSignature';
import { useStamp } from 'src/hooks/useStamp';
import { isValidDate, makeDateToString } from 'src/util/time';
import { FieldtripResultDetailPage } from './FieldtripResultDetailPage';

export function FieldtripResultPage() {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const isDetail = !pathname.endsWith('/teacher/fieldtrip/result');
  const { canvasRef, sigPadData, clearSignature } = useSignature();
  const { t } = useLanguage();

  const filters = [
    { id: 1, name: t('approval_state', '승인 상태'), value: 'ALL' },
    { id: 2, name: t('pending_approval', '승인 전'), value: 'BEFORE_APPROVAL' },
    { id: 3, name: t('approved', '승인 완료'), value: 'PROCESSED' },
    { id: 4, name: t('rejected', '반려됨'), value: 'RETURNED' },
  ];

  const { me } = UserContainer.useContext();
  const { allKlassGroups: groups } = GroupContainer.useContext();

  const { stamp, stampImgUrl, stampMode, setStampMode, updateStamp } = useStamp();

  const [frontSortType, setFrontSortType] = useState('');

  const frontSort = (sortType: string) => {
    setFrontSortType(sortType);
  };

  function makeStudentNumber(studentGradeKlass: string, studentNumber: string): number {
    const grade = parseInt(studentGradeKlass.split(' ')[0]);
    const klass = parseInt(studentGradeKlass.split(' ')[1]);
    return grade * 10000 + klass * 100 + parseInt(studentNumber);
  }

  const {
    error,
    errorMessage,
    isLoading,
    startDate,
    endDate,
    filter,
    _studentName,
    data,
    limit,
    page,
    agreeAll,
    open,
    isViewAuth,
    isApprovalAuth,
    setLoading,
    setPage,
    setStartDate,
    setEndDate,
    setFilter,
    setOpen,
    setAgreeAll,
    selectedGroup,
    setSelectedGroup,
    set_studentName,
    setFieldtripId,
    approveFieldtripResult,
    approveFieldtripResults,
    searchAlert,
  } = useTeacherFieldtripResult({ clearSignature, sigPadData, stampMode, stampImgUrl, stamp });

  const onClickApproveByStamp = () => {
    setLoading(true);
    if (!stamp) {
      alert('도장이 등록되어 있지 않습니다.');
      setLoading(false);
    } else {
      if (agreeAll) {
        approveFieldtripResults();
      } else {
        approveFieldtripResult();
      }
      setStampMode(false);
    }
  };

  useEffect(() => {
    if (open) {
      if (stamp) {
        setStampMode(true);
      } else {
        setStampMode(false);
      }
    }
  }, [open]);

  return (
    <>
      {error && <ErrorBlank />}
      {isLoading && <Blank reversed />}
      {errorMessage && <ErrorBlank text={errorMessage} />}
      <div className="md:hidden">
        <TopNavbar title="체험학습 결과보고서" left={<BackButton />} />
      </div>
      <div className={`col-span-3 h-screen-7 md:h-screen ${isDetail && 'hidden'}  md:block`}>
        <div className=" scroll-box overflow-y-scroll px-6 pb-4 md:pt-6">
          <div className="flex hidden justify-between md:block">
            <h1 className="text-2xl font-semibold">{t('experiential_learning_report', '체험학습 결과보고서')}</h1>
          </div>
          <div className="my-3 flex items-center space-x-3">
            <TextInput
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
                setPage(1);
              }}
            />
            <div className="px-4 text-xl font-bold">~</div>
            <TextInput
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <div className="flex items-center space-x-2">
            <div className=" min-w-max cursor-pointer px-2 py-2">
              <SelectMenus allText="모두" items={filters} value={filter} onChange={(e) => setFilter(e)} />
            </div>

            {(me?.role === Role.PRE_HEAD ||
              me?.role === Role.HEAD ||
              me?.role === Role.PRE_PRINCIPAL ||
              me?.role === Role.PRINCIPAL ||
              me?.role === Role.VICE_PRINCIPAL ||
              me?.role === Role.HEAD_PRINCIPAL ||
              me?.role === Role.SECURITY ||
              me?.role === Role.ADMIN) && (
              <>
                <div className="min-w-max cursor-pointer">
                  <SelectMenus
                    allText="전체반"
                    allTextVisible
                    items={groups?.filter((el) =>
                      me?.role === Role.PRE_HEAD || me?.role === Role.HEAD
                        ? el.name?.startsWith(me?.headNumber.toString())
                        : true,
                    )}
                    value={selectedGroup}
                    onChange={(group: any) => setSelectedGroup(group)}
                  />
                </div>
              </>
            )}

            <div className="flex w-full items-center space-x-2">
              <SearchInput
                placeholder={`${t('search_by_name', '이름 검색')}`}
                value={_studentName}
                onChange={(e) => {
                  set_studentName(e.target.value);
                  if (e.target.value === '') replace(`/teacher/fieldtrip`);
                }}
                onSearch={() => _studentName && replace(`/teacher/fieldtrip/result?username=${_studentName}`)}
                className="w-full"
              />
              <Icon.Search
                onClick={() => {
                  _studentName === ''
                    ? alert('텍스트 내용을 입력해주세요.')
                    : replace(`/teacher/fieldtrip/result?username=${_studentName}`);
                }}
                className="cursor-pointer"
              />
            </div>
            {isApprovalAuth && (
              <Button.xl
                children={t('bulk_approve', '일괄 승인하기')}
                onClick={() => {
                  if (filter.value === 'BEFORE_APPROVAL') {
                    if (data && data.total > 0) {
                      setOpen(true);
                      setAgreeAll(true);
                    } else {
                      alert('승인할 서류가 없습니다.');
                    }
                  } else {
                    searchAlert();
                  }
                }}
                className="filled-primary h-auto min-w-max py-2.5"
              />
            )}
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <div className="grid grid-cols-4 bg-gray-100 max-md:hidden">
          <button onClick={() => frontSort('period')} className="flex items-center justify-center">
            <span>{t('by_date', '기간순')}</span>
            {frontSortType === 'period' && <Icon.ChevronDown />}
          </button>
          <button onClick={() => frontSort('request')} className="flex items-center justify-center">
            <span>{t('by_application_date', '신청일순')}</span>
            {frontSortType === 'request' && <Icon.ChevronDown />}
          </button>
          <button onClick={() => frontSort('name')} className="flex items-center justify-center">
            <span>{t('by_name', '이름순')}</span>
            {frontSortType === 'name' && <Icon.ChevronDown />}
          </button>
          <button onClick={() => frontSort('num')} className="flex items-center justify-center">
            <span>{t('by_student_id', '학번순')}</span>
            {frontSortType === 'num' && <Icon.ChevronDown />}
          </button>
        </div>
        {!isViewAuth && !isApprovalAuth && <div className="text-center">권한이 없습니다.</div>}
        {isViewAuth && (
          <div className="h-screen-16 overflow-y-auto pb-10 md:pb-0">
            {data?.items
              ?.sort((a, b) => {
                if (frontSortType === 'period') {
                  return a.startAt < b.startAt ? 1 : a.startAt > b.startAt ? -1 : 0;
                } else if (frontSortType === 'request') {
                  return a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;
                } else if (frontSortType === 'num') {
                  const studentNumberA = makeStudentNumber(a.studentGradeKlass, a.studentNumber.toString());
                  const studentNumberB = makeStudentNumber(b.studentGradeKlass, b.studentNumber.toString());
                  return studentNumberA < studentNumberB ? -1 : studentNumberA > studentNumberB ? 1 : 0;
                } else if (frontSortType === 'name') {
                  return a.student?.name < b.student?.name ? -1 : a.student?.name > b.student?.name ? 1 : 0;
                }
                return 0;
              })
              .map((fieldtrip) => <FieldtripResultCard key={fieldtrip.id} fieldtrip={fieldtrip} />)}
          </div>
        )}
        {data && data.total > limit && (
          <div className="grid min-w-max place-items-center">
            <FrontPagination
              basePath="/teacher/fieldtrip/result"
              total={data?.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      <div className="col-span-3 bg-gray-50 md:p-6">
        <Switch>
          <Route
            path="/teacher/fieldtrip/result/:id"
            component={() => (
              <FieldtripResultDetailPage
                school={me?.school}
                setOpen={(b: boolean) => setOpen(b)}
                setAgreeAll={(b: boolean) => setAgreeAll(b)}
                setFieldtripId={(n: number) => setFieldtripId(n)}
                me={me}
              />
            )}
          />
        </Switch>
      </div>
      <SuperModal
        modalOpen={open}
        setModalClose={() => {
          setStampMode(false);
          clearSignature();
          setOpen(false);
        }}
        width="w-max"
        ablePropragation
      >
        <Section className="mt-7">
          <div>
            <div className="text-xl font-bold text-gray-700">서명란</div>
            <div className="text-gray-500">아래 네모칸에 이름을 바르게 적어주세요.</div>
          </div>
          <div className="relative">
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              className="m-auto rounded-[30px] bg-[#F2F2F2]"
            />
            {stampMode ? (
              stampImgUrl ? (
                <div
                  className="absolute inset-0 z-10 overflow-hidden rounded bg-contain bg-center bg-no-repeat"
                  style={{ backgroundImage: `url("${stampImgUrl}")` }}
                ></div>
              ) : (
                <div className="absolute inset-0 z-10 overflow-hidden rounded bg-grey-4">
                  <div className="flex h-full w-full items-center justify-center">
                    <div className="min-w-max text-center">도장을 등록해주세요.</div>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>
          <div className="grid grid-cols-2 gap-2">
            <label>
              <p className="button-xl cursor-pointer border border-brandblue-1 bg-white text-current">도장등록</p>
              <input
                type="file"
                accept=".png, .jpeg, .jpg"
                onChange={(e) => {
                  if (!e.target?.files) return;
                  updateStamp(e.target.files[0]);
                  setStampMode(true);
                }}
                className="sr-only"
              />
            </label>
            {!stampMode ? (
              <Button.xl
                children="도장 사용하기"
                onClick={() => {
                  setStampMode(true);
                  clearSignature();
                }}
                className="min-w-max border border-brandblue-1 bg-white text-brand-1"
              />
            ) : (
              <Button.xl
                children="도장으로 승인"
                onClick={onClickApproveByStamp}
                className={clsx(
                  'text-white',
                  stampImgUrl ? 'border-4 border-red-500 bg-brandblue-1' : 'bg-brandblue-5',
                )}
              />
            )}
            <Button.xl
              children="서명 다시하기"
              onClick={() => {
                setStampMode(false);
                clearSignature();
              }}
              className="outlined-primary text-current"
            />
            {stampMode ? (
              <Button.xl children="서명 사용하기" onClick={() => setStampMode(false)} className="outlined-primary" />
            ) : (
              <Button.xl
                children="서명으로 승인"
                onClick={() => {
                  if (!sigPadData) {
                    alert('서명 후 승인해 주세요.');
                  } else {
                    setLoading(true);
                    agreeAll ? approveFieldtripResults() : approveFieldtripResult();
                  }
                }}
                className={clsx('text-white', sigPadData ? 'border-4 border-green-500 bg-brand-1' : 'bg-brand-5')}
              />
            )}
          </div>
        </Section>
      </SuperModal>
    </>
  );
}

import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { ErrorBlank } from 'src/components';
import { BackButton, Blank, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { PdfViewer } from 'src/components/common/PdfViewer';
import { FieldtripPaper } from 'src/components/fieldtrip/FieldtripPaper';
import { FieldtripSeparatePaper } from 'src/components/fieldtrip/FieldtripSeparatePaper';
import { FieldtripSuburbsSeparatePaper } from 'src/components/fieldtrip/FieldtripSuburbsSeparatePaper';
import { FieldtripSuburbsTextSeparatePaper } from 'src/components/fieldtrip/FieldtripSuburbsTextSeparatePaper';
import { Constants } from 'src/constants';
import { useFieldtripResultDetail } from 'src/container/student-fieldtrip-result-detail';
import { UserContainer } from 'src/container/user';
import { FieldtripStatus, Role } from 'src/generated/model';
import { useSignedUrl } from 'src/lib/query';
import { isPdfFile } from 'src/util/file';
import { getNickName } from 'src/util/status';

export function FieldtripResultDetailPage() {
  let { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { me } = UserContainer.useContext();

  const { isLoading, isError, resendAlimtalk, fieldtrip, errorMessage } = useFieldtripResultDetail({
    id: Number(id),
  });

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hasPdfModalOpen, setPdfModalOpen] = useState(false);
  const [focusPdfFile, setFocusPdfFile] = useState('');

  const { data: resultApprover1Signature } = useSignedUrl(fieldtrip?.resultApprover1Signature);
  const { data: resultApprover2Signature } = useSignedUrl(fieldtrip?.resultApprover2Signature);
  const { data: resultApprover3Signature } = useSignedUrl(fieldtrip?.resultApprover3Signature);
  const { data: resultApprover4Signature } = useSignedUrl(fieldtrip?.resultApprover4Signature);
  const { data: resultApprover5Signature } = useSignedUrl(fieldtrip?.resultApprover5Signature);

  const images = fieldtrip?.resultFiles.filter((image) => !isPdfFile(image)) || [];
  const Pdfs = fieldtrip?.resultFiles.filter((image) => isPdfFile(image)) || [];

  const viewerImages: ImageDecorator[] = [];
  for (const image of images) {
    if (isPdfFile(image) == false) {
      viewerImages.push({
        src: `${Constants.imageUrl}${image}`,
      });
    }
  }

  let name = '가정';

  switch (fieldtrip?.type) {
    case 'HOME':
      name = '가정';
      break;
    case 'SUBURBS':
      name = '교외 체험';
      break;
  }

  let content = [];

  try {
    content = JSON.parse(fieldtrip?.resultText || '[]');
  } catch (err) {
    console.log(err);
  }

  const [resultTextPage1, setResultTextPage1] = useState('');
  const [resultTextPageRemains, setResultTextPageRemains] = useState<string[]>([]);

  const separateResultText = (resultText: string | undefined) => {
    if (resultText) {
      const maxLine = 19;
      const charsOfLine = 40;
      let lineNum = 0;

      resultText = resultText.replace(/\n{2,}/g, '\n\n'); // 줄바꿈하나로 합치기
      resultText += '\n';

      const sentences = resultText.split('\n');

      for (const str of sentences) {
        const Countline = Math.floor(str.length / charsOfLine) + 1;

        if (lineNum + Countline > maxLine) {
          const index = resultText.indexOf(str + '\n');
          setResultTextPage1(resultText.substring(0, index));
          separateResultTextRemains(resultText.substring(index));
          break;
        } else {
          lineNum = lineNum + Countline;
        }
      }
    }
  };

  const separateResultTextRemains = (resultText: string | undefined) => {
    if (resultText) {
      const separatedPage: string[] = [];

      const maxLine = 34;
      const charsOfLine = 40;
      let lineNum = 0;

      let startLine = 0;

      const sentences = resultText.split('\n');

      for (const str of sentences) {
        const Countline = Math.floor(str.length / charsOfLine) + 1;

        if (lineNum + Countline > maxLine) {
          const index = resultText.indexOf(str);
          separatedPage.push(resultText.substring(startLine, index));

          lineNum = Countline;
          startLine = index;
        } else {
          lineNum = lineNum + Countline;
        }
      }

      separatedPage.push(resultText.substring(startLine));

      setResultTextPageRemains(separatedPage);
    }
  };

  let homeplans: any = [];
  const resultFilesWithTwo: any = [];

  try {
    if (fieldtrip?.type === 'HOME') {
      const _content = JSON.parse(fieldtrip?.resultText || '[]');
      content = _content[0];
      if (content.subject1) {
        homeplans = _content?.slice(1);
      } else {
        const subContent = _content?.slice(5);
        homeplans = Array.from({ length: Math.ceil(subContent.length / 10) }, (_, index) =>
          subContent.slice(index * 10, index * 10 + 10),
        );
      }
    }
  } catch (err) {
    console.log(err);
  }

  try {
    if (fieldtrip?.resultFiles instanceof Array) {
      let chunk = [];

      for (let i = 0; i < fieldtrip?.resultFiles?.length; i++) {
        chunk.push(fieldtrip?.resultFiles[i]);
        if (i % 2 === 1) {
          resultFilesWithTwo.push(chunk);
          chunk = [];
        }
      }
      if (chunk.length > 0) {
        resultFilesWithTwo.push(chunk);
      }
    }
  } catch (err) {
    console.log(err);
  }

  return (
    <>
      {isLoading && <Blank />}
      {isError && <ErrorBlank />}
      {isLoading && <Blank />}
      <TopNavbar
        title="체험학습 결과보고서 상세"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      {fieldtrip?.fieldtripResultStatus === 'RETURNED' && (
        <div className="flex items-center justify-between rounded-lg bg-brand-5 px-5 py-2">
          <div className="text-sm text-brand-1">{fieldtrip?.notApprovedReason}</div>
          <div className="text-red-500">반려 이유</div>
        </div>
      )}

      <div className="w-full bg-white p-5">
        <FieldtripPaper
          school={me?.school}
          fieldtrip={fieldtrip}
          content={content}
          type="결과보고서"
          resultTextPage1={resultTextPage1}
        />
      </div>

      {fieldtrip?.type === 'HOME' && (
        <>
          {homeplans?.map((content: any, i: number) => (
            <div key={i} className="w-full bg-white p-5">
              <FieldtripSeparatePaper
                studentName={fieldtrip?.student?.name + getNickName(fieldtrip?.student?.nickName)}
                studentGradeKlass={fieldtrip?.studentGradeKlass + ' ' + fieldtrip?.studentNumber + '번'}
                fieldtrip={fieldtrip}
                index={i + 1}
                content={content}
                type="결과보고서"
              />
            </div>
          ))}
        </>
      )}

      {fieldtrip?.type === 'SUBURBS' && resultTextPageRemains.length > 0 && (
        <>
          {resultTextPageRemains.map((el: any, i: number) => (
            <div key={i} className="w-full bg-white p-5">
              <FieldtripSuburbsTextSeparatePaper
                studentName={fieldtrip?.student?.name || ''}
                fieldtrip={fieldtrip}
                resultTextPage={el}
              />
            </div>
          ))}
        </>
      )}
      {fieldtrip?.type === 'SUBURBS' && (
        <>
          {resultFilesWithTwo.map((el: any, i: number) => (
            <div key={i} className="w-full bg-white p-5">
              <FieldtripSuburbsSeparatePaper
                studentName={(fieldtrip?.student?.name || '') + getNickName(fieldtrip?.student?.nickName)}
                fieldtrip={fieldtrip}
                resultFile1={el[0]}
                resultFile2={el[1]}
              />
            </div>
          ))}
        </>
      )}

      <Section>
        {fieldtrip?.type === 'SUBURBS' && (
          <>
            {me?.role === Role.PARENT && fieldtrip?.fieldtripResultStatus === FieldtripStatus.BEFORE_PARENT_CONFIRM && (
              <Button.lg
                children="승인하기"
                onClick={() => push(`/fieldtrip/result/approve/${fieldtrip?.id}`)}
                className={'bg-brand-1 text-white'}
              />
            )}

            {(fieldtrip?.fieldtripResultStatus === FieldtripStatus.BEFORE_PARENT_CONFIRM ||
              fieldtrip?.fieldtripResultStatus === FieldtripStatus.RETURNED ||
              fieldtrip?.nextResultApprover === 'resultApprover1') && (
              <Button.lg
                children="수정하기"
                onClick={() => push(`/student/fieldtrip/add/report/suburbs/${fieldtrip.id}`)}
                className={'bg-yellow-500 text-white'}
              />
            )}
            {me?.role != Role.PARENT && fieldtrip?.fieldtripResultStatus === FieldtripStatus.BEFORE_PARENT_CONFIRM && (
              <Button.lg
                children="알림톡 재전송하기"
                onClick={() => resendAlimtalk()}
                className="bg-blue-500 text-white"
              />
            )}
            <div className="text-red-500">{errorMessage}</div>
          </>
        )}
        {fieldtrip?.type === 'HOME' && (
          <>
            {me?.role === Role.PARENT && fieldtrip?.fieldtripResultStatus === FieldtripStatus.BEFORE_PARENT_CONFIRM && (
              <Button.lg
                children="승인하기"
                onClick={() => push(`/fieldtrip/result/approve/${fieldtrip?.id}`)}
                className={'bg-brand-1 text-white'}
              />
            )}

            {(fieldtrip?.fieldtripResultStatus === FieldtripStatus.BEFORE_PARENT_CONFIRM ||
              fieldtrip?.fieldtripResultStatus === FieldtripStatus.RETURNED ||
              fieldtrip?.nextResultApprover === 'resultApprover1') && (
              <Button.lg
                children="수정하기"
                onClick={() => push(`/student/fieldtrip/add/report/home/${fieldtrip.id}`)}
                className={'bg-yellow-500 text-white'}
              />
            )}
            {me?.role != Role.PARENT && fieldtrip?.fieldtripResultStatus === FieldtripStatus.BEFORE_PARENT_CONFIRM && (
              <Button.lg
                children="알림톡 재전송하기"
                onClick={() => resendAlimtalk()}
                className="bg-blue-500 text-white"
              />
            )}
            <div className="text-red-500">{errorMessage}</div>
          </>
        )}

        <br />

        <div className="h-32 w-full" />
      </Section>
      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable
          noImgDetails
          scalable={false}
          images={viewerImages}
          onClose={() => setImagesModalOpen(false)}
          activeIndex={activeIndex}
        />
      </div>
      <div className="absolute">
        <PdfViewer isOpen={hasPdfModalOpen} fileUrl={focusPdfFile} onClose={() => setPdfModalOpen(false)} />
      </div>
    </>
  );
}

import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { BackButton, Select, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { useSchoolsFindAll } from 'src/generated/endpoint';
import { useLanguage } from 'src/hooks/useLanguage';
import { buildSearch } from 'src/lib/router';
import { Routes } from 'src/routes';

export function SelectSchool() {
  const { t } = useLanguage();
  const [schoolId, setSchoolId] = useState<number>();

  const { data: allSchools } = useSchoolsFindAll();

  const schools = useMemo(() => allSchools?.filter((school) => school.selfJoin !== 0) ?? [], [allSchools]);

  useEffect(() => schools[0] && setSchoolId(schools[0].id), [schools]);

  return (
    <>
      <TopNavbar
        borderless
        title={`${t('select_school_page.title')}`}
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      <div className="mx-auto flex w-full max-w-xl flex-col gap-8 p-8">
        <h2 className="text-20">{t('select_school_page.select_school')}</h2>

        <Select.xl value={schoolId} onChange={(e) => setSchoolId(Number(e.target.value))}>
          {schools.map((school) => (
            <option key={school.id} value={school.id}>
              {school.name}
            </option>
          ))}
        </Select.xl>

        {schoolId && (
          <Button.xl
            as={Link}
            to={{ pathname: Routes.auth.signup, search: buildSearch({ schoolId }) }}
            children={t('select_school_page.signup')}
            className="filled-primary"
          />
        )}
      </div>
    </>
  );
}

export interface examsScoreFiles {
  file: File;
  grade: number;
  class_num: number;
  cur_year: number;
}

export interface mockExamScoreFiles {
  file: File;
  grade: number;
  classNum: number;
  semester: number;
  step: number;
  insertionYear: number;
}

export function validateAndExtract(files: File[]) {
  const validFiles: examsScoreFiles[] = [];
  const invalidFiles: string[] = [];

  Array.from(files).forEach((file) => {
    const normalizedName = file.name.normalize('NFC');
    const match = normalizedName.match(/^(\d{1,2})-(\d{1,2})-종합\..+$/);
    if (match) {
      const grade = parseInt(match[1], 10); // 학년 추출
      const class_num = parseInt(match[2], 10); // 반 추출
      const cur_year = new Date().getFullYear(); // 현재 연도
      validFiles.push({ file, grade, class_num, cur_year });
    } else {
      invalidFiles.push(file.name);
    }
  });

  if (invalidFiles.length > 0) {
    alert(`잘못된 파일 이름: ${invalidFiles.join(', ')}`);
    return [];
  }

  return validFiles;
}

export function validateAndExtractMock(files: File[], semester: number, step: number) {
  const validFiles: mockExamScoreFiles[] = [];
  const invalidFiles: string[] = [];

  Array.from(files).forEach((file) => {
    const normalizedName = file.name.normalize('NFC');
    const match = normalizedName.match(/^(\d{1,2})-(\d{1,2})-(\d{1,2})-(\d{1,2})\..+$/);
    if (match) {
      const grade = parseInt(match[1], 10); // 학년 추출
      const classNum = parseInt(match[2], 10); // 반 추출
      const insertionYear = new Date().getFullYear(); // 현재 연도
      validFiles.push({ file, grade, classNum, insertionYear, semester, step });
    } else {
      invalidFiles.push(file.name);
    }
  });

  if (invalidFiles.length > 0) {
    alert(`잘못된 파일 이름: ${invalidFiles.join(', ')}`);
    return [];
  }

  return validFiles;
}

import { jsPDF } from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { SuperModal } from 'src/components';
import { Blank, Section, Textarea } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { FieldtripPaper } from 'src/components/fieldtrip/FieldtripPaper';
import { FieldtripSeparatePaper } from 'src/components/fieldtrip/FieldtripSeparatePaper';
import { FieldtripSuburbsSeparatePaper } from 'src/components/fieldtrip/FieldtripSuburbsSeparatePaper';
import { FieldtripSuburbsTextSeparatePaper } from 'src/components/fieldtrip/FieldtripSuburbsTextSeparatePaper';
import { useTeacherFieldtripResultDetail } from 'src/container/teacher-fieldtrip-result-detail';
import { ResponseUserDto } from 'src/generated/model';
import { approveButtonType } from 'src/types';
import { extractReactData } from 'src/util/pdf';
import { buttonEnableState } from 'src/util/permission';
import { makeDateToString, makeStartEndToString, makeTimeToString } from 'src/util/time';
import { FieldtripResultUpdatePage } from './FieldtripResultUpdatePage';
import { getNickName } from 'src/util/status';

interface FieldtripResultDetailPageProps {
  school: ResponseUserDto['school'] | undefined;
  setOpen: (b: boolean) => void;
  setAgreeAll: (b: boolean) => void;
  setFieldtripId?: (n: number) => void;
  me: ResponseUserDto | undefined;
}

const calculateStatus = (role: string, order: 'before' | 'after' | 'show') => {
  const roles = ['TEACHER', 'PRE_HEAD', 'HEAD', 'PRE_PRINCIPAL', 'PRINCIPAL', 'VICE_PRINCIPAL', 'HEAD_PRINCIPAL'];
  const _fieldtripStatus = [
    'BEFORE_TEACHER_APPROVAL',
    'TEACHER',
    'BEFORE_PRE_HEAD_APPROVAL',
    'PRE_HEAD',
    'BEFORE_HEAD_APPROVAL',
    'HEAD',
    'BEFORE_PRE_PRINCIPAL_APPROVAL',
    'PRE_PRINCIPAL',
    'BEFORE_PRINCIPAL_APPROVAL',
    'PRINCIPAL',
    'BEFORE_VICE_PRINCIPAL_APPROVAL',
    'VICE_PRINCIPAL',
    'BEFORE_HEAD_PRINCIPAL_APPROVAL',
    'HEAD_PRINCIPAL',
    'PROCESSED',
  ];

  if (role === 'SECURITY') return ['PROCESSED'];

  const index = _fieldtripStatus.findIndex((el) => el === role);

  if (index === -1) return [];

  if (order === 'before') {
    return _fieldtripStatus.slice(index - 1, index).filter((el) => !roles.includes(el));
  } else if (order === 'after') {
    return _fieldtripStatus.slice(index).filter((el) => !roles.includes(el));
  } else if (order === 'show') {
    return _fieldtripStatus.slice(index - 1).filter((el) => !roles.includes(el));
  }
};

export function FieldtripResultDetailPage({
  school,
  setOpen,
  setAgreeAll,
  setFieldtripId,
  me,
}: FieldtripResultDetailPageProps) {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const ref = useRef(null);
  const refTextPageRemain = useRef<any[]>([]);
  const planRef = useRef(null);
  const separatePaperRefs = useRef<any[]>([]);

  const [notApprovedReason, setNotApprovedReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const [clicked, setClicked] = useState(false);
  const [readState, setReadState] = useState(true);

  const [resultTextPage1, setResultTextPage1] = useState('');
  const [resultTextPageRemains, setResultTextPageRemains] = useState<string[]>([]);

  const [download, setDownload] = useState(false);

  const {
    denyFieldtripResult,
    deleteAppealFieldtripResult,
    isLoading,
    fieldtrip,
    deleteAppeal,
    loading,
    deny,
    setDeleteAppeal,
    setDeny,
    setLoading,
    resendAlimtalk,
  } = useTeacherFieldtripResultDetail({ id });

  const splitStringByUnicode = (str: string, chunkSize: number) => {
    const chunks: string[] = [];
    let index = 0;

    const unicodeStr = str.replace(
      /[\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7A3\uD7B0-\uD7FF]|./g,
      (match) => {
        // 한글 문자에 ^를 추가하는 로직
        if (/[\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7A3\uD7B0-\uD7FF]/.test(match)) {
          return '^' + match;
        } else {
          return match;
        }
      },
    );

    while (index < unicodeStr.length) {
      const chunk = unicodeStr.substring(index, index + chunkSize * 2);

      chunks.push(chunk.replace(/\^/g, ''));
      index += chunk.length;
    }

    return chunks;
  };

  const separateResultText = (resultText: string | undefined) => {
    if (resultText) {
      const maxLine = 21;
      const charsOfLine = 42;

      resultText = resultText.replace(/\n{2,}/g, '\n'); // 줄바꿈하나로 합치기
      resultText += '\n';

      const sentences = resultText.split('\n');

      const lines: string[] = [];

      sentences.map((str) => {
        const chunks = splitStringByUnicode(str, charsOfLine);
        lines.push(...chunks);
      });

      const index = resultText.indexOf(lines[maxLine]);
      setResultTextPage1(index > 0 ? resultText.substring(0, index) : resultText);
      separateResultTextRemains(index > 0 ? resultText.substring(index) : '');
    }
  };

  const separateResultTextRemains = (resultText: string | undefined) => {
    const separatedPage: string[] = [];
    if (resultText) {
      const maxLine = 34;
      const charsOfLine = 40;
      const sentences = resultText.split('\n');

      const lines: string[] = [];

      sentences.map((str) => {
        const chunks = splitStringByUnicode(str, charsOfLine);
        lines.push(...chunks);
      });

      for (let i = 0; i < lines.length; i += maxLine) {
        const pageContent = lines.slice(i, i + maxLine).join('');
        separatedPage.push(pageContent);
      }
    }
    setResultTextPageRemains(separatedPage);
  };

  useEffect(() => {
    separateResultText(fieldtrip?.resultText);
  }, [fieldtrip]);

  useEffect(() => {
    setFieldtripId && setFieldtripId(Number(id));
  }, [id]);

  let homeplans: any = [];
  let content;
  const resultFilesWithTwo: any = [];

  try {
    if (fieldtrip?.type === 'HOME') {
      const _content = JSON.parse(fieldtrip?.resultText || '[]');
      content = _content[0];
      if (content.subject1) {
        homeplans = _content?.slice(1);
      } else {
        const subContent = _content?.slice(5);
        homeplans = Array.from({ length: Math.ceil(subContent.length / 10) }, (_, index) =>
          subContent.slice(index * 10, index * 10 + 10),
        );
      }
    }
  } catch (err) {
    console.log(err);
  }

  try {
    if (fieldtrip?.resultFiles instanceof Array) {
      let chunk = [];

      for (let i = 0; i < fieldtrip?.resultFiles?.length; i++) {
        chunk.push(fieldtrip?.resultFiles[i]);
        if (i % 2 === 1) {
          resultFilesWithTwo.push(chunk);
          chunk = [];
        }
      }
      if (chunk.length > 0) {
        resultFilesWithTwo.push(chunk);
      }
    }
  } catch (err) {
    console.log(err);
  }

  const buttonDisabled = !(fieldtrip?.nextResultApproverId === me?.id);

  // 결재권자 인지. 결재라인에 있으면 true, 없으면 false
  const approver =
    fieldtrip?.resultApprover1Id === me?.id ||
    fieldtrip?.resultApprover2Id === me?.id ||
    fieldtrip?.resultApprover3Id === me?.id ||
    fieldtrip?.resultApprover4Id === me?.id ||
    fieldtrip?.resultApprover5Id === me?.id;

  const approvedLine = [
    fieldtrip?.resultApprover1Signature && fieldtrip?.resultApprover1Id,
    fieldtrip?.resultApprover2Signature && fieldtrip?.resultApprover2Id,
    fieldtrip?.resultApprover3Signature && fieldtrip?.resultApprover3Id,
    fieldtrip?.resultApprover4Signature && fieldtrip?.resultApprover4Id,
    fieldtrip?.resultApprover5Signature && fieldtrip?.resultApprover5Id,
  ];
  // 승인할 차례 : true, 승인전/승인후 : false
  const nowApprove = fieldtrip?.nextResultApproverId === me?.id;

  // 내가 승인한 건 : ture , 승인 안한 건 : false
  const isApproved = nowApprove ? false : approvedLine.includes(me?.id);

  // 승인 전 = !isApproved && !nowApprove
  // 승인 후 = isApproved && !nowApprove

  const checkButtonDisable = (bottonType: approveButtonType) => {
    return !buttonEnableState(
      bottonType,
      approver,
      isApproved,
      nowApprove,
      fieldtrip?.fieldtripResultStatus || '',
      fieldtrip?.studentGradeKlass === me?.klassGroupName,
    );
  };

  if (!fieldtrip || fieldtrip?.fieldtripResultStatus === 'BEFORE_PARENT_CONFIRM') {
    return (
      <div className="relative flex h-screen-7 items-center justify-center rounded-lg border bg-white py-5 text-center">
        <div className="absolute left-0 top-5">
          <div className="flex w-full items-center justify-start space-x-2 px-5">
            <div
              className="cursor-pointer text-brand-1 underline"
              onClick={() => fieldtrip && push(`/teacher/fieldtrip/${fieldtrip.id}`)}
            >
              신청서
            </div>
            <div
              className="cursor-pointer text-brand-1 underline"
              onClick={() => fieldtrip && push(`/teacher/fieldtrip/notice/${fieldtrip.id}`)}
            >
              통보서
            </div>
            <div className="cursor-pointer text-brand-1 underline">결과보고서</div>
          </div>
        </div>
        {fieldtrip ? (
          <div className="bg-white p-5 text-lg">
            학생이 작성한 결과보고서의 보호자 승인이 필요합니다.
            <div className="relative flex items-center justify-center pt-2">
              {fieldtrip?.fieldtripResultStatus.toString() === 'BEFORE_PARENT_CONFIRM' && (
                <Button.lg
                  children="알림톡 재전송"
                  onClick={() => resendAlimtalk()}
                  className="bg-blue-500 text-white"
                />
              )}
            </div>
          </div>
        ) : (
          <div className="bg-white p-5 text-lg">아직 결과보고서가 작성되지 않았습니다.</div>
        )}
      </div>
    );
  }

  if (!readState) {
    return (
      <FieldtripResultUpdatePage
        school={school}
        fieldtrip={fieldtrip}
        setReadState={() => setReadState(true)}
        isConfirmed={isApproved}
      />
    );
  }

  return (
    <div className="h-screen-10">
      {loading && <Blank reversed />}
      {isLoading && <Blank reversed />}

      <div className="h-screen-10 bg-white py-5 md:h-screen-7 md:rounded-lg md:border">
        <div className="relative h-full w-auto overflow-scroll">
          <div className="flex w-full items-center justify-start space-x-2 px-5 ">
            <div
              className="cursor-pointer text-brand-1 underline"
              onClick={() => push(`/teacher/fieldtrip/${fieldtrip.id}`)}
            >
              신청서
            </div>
            <div
              className="cursor-pointer text-brand-1 underline"
              onClick={() => push(`/teacher/fieldtrip/notice/${fieldtrip.id}`)}
            >
              통보서
            </div>
            <div className="cursor-pointer text-brand-1 underline">결과보고서</div>
          </div>
          {fieldtrip?.fieldtripResultStatus === 'RETURNED' && fieldtrip?.notApprovedReason && fieldtrip?.updatedAt && (
            <div className="mx-5 flex items-center justify-between rounded-lg bg-brand-5 px-5 py-2">
              <div className="text-brand-1">{fieldtrip?.notApprovedReason}</div>
              <div className="text-sm text-gray-500">
                {makeDateToString(new Date(fieldtrip?.updatedAt))} {makeTimeToString(new Date(fieldtrip?.updatedAt))}에
                마지막으로 수정
              </div>
            </div>
          )}
          {fieldtrip?.updateReason && fieldtrip?.updatedAt && (
            <div className="flex items-center justify-between rounded-lg bg-brand-5 px-5 py-2">
              <div className="text-brand-1">{fieldtrip?.updateReason}</div>
              <div className="text-sm text-gray-500">
                {makeDateToString(fieldtrip?.updatedAt)} {makeTimeToString(fieldtrip?.updatedAt)}에 마지막으로 수정
              </div>
            </div>
          )}

          <div ref={ref} className={`md:h-[1100px] ${download ? 'w-[778px]' : 'w-full p-5 md:p-0'} bg-white `}>
            <FieldtripPaper
              school={school}
              fieldtrip={fieldtrip}
              content={content}
              type="결과보고서"
              resultTextPage1={resultTextPage1}
              isPaper={download}
            />
          </div>

          {fieldtrip?.type === 'HOME' && (
            <>
              {homeplans?.map((content: any, i: number) => (
                <div
                  key={i}
                  ref={(el) => (separatePaperRefs.current[i] = el)}
                  className={` ${download ? 'h-[1100px] w-[778px] p-15' : 'w-full p-5 md:p-12'} bg-white `}
                >
                  <FieldtripSeparatePaper
                    studentName={fieldtrip?.student?.name + getNickName(fieldtrip?.student?.nickName)}
                    studentGradeKlass={fieldtrip?.studentGradeKlass + ' ' + fieldtrip?.studentNumber + '번'}
                    fieldtrip={fieldtrip}
                    index={i + 1}
                    content={content}
                    type="결과보고서"
                  />
                </div>
              ))}
            </>
          )}

          {fieldtrip?.type === 'SUBURBS' && resultTextPageRemains.length > 0 && (
            <>
              {resultTextPageRemains.map((el: any, i: number) => (
                <div
                  key={i}
                  ref={(el) => (refTextPageRemain.current[i] = el)}
                  className={` ${download ? 'h-[1100px] w-[778px] p-15' : 'w-full p-5 md:p-12'} bg-white `}
                >
                  <FieldtripSuburbsTextSeparatePaper
                    studentName={(fieldtrip?.student?.name || '') + getNickName(fieldtrip?.student?.nickName)}
                    fieldtrip={fieldtrip}
                    resultTextPage={el}
                  />
                </div>
              ))}
            </>
          )}
          {fieldtrip?.type === 'SUBURBS' && (
            <>
              {resultFilesWithTwo.map((el: any, i: number) => (
                <div
                  key={i}
                  ref={(el) => (separatePaperRefs.current[i] = el)}
                  className={` ${download ? 'h-[1100px] w-[778px] p-15' : 'w-full p-5 md:p-12'} bg-white `}
                >
                  <FieldtripSuburbsSeparatePaper
                    studentName={(fieldtrip?.student?.name || '') + getNickName(fieldtrip?.student?.nickName)}
                    fieldtrip={fieldtrip}
                    resultFile1={el[0]}
                    resultFile2={el[1]}
                  />
                </div>
              ))}
            </>
          )}
        </div>

        <div className="mt-3 grid grid-cols-4 gap-2 overflow-x-auto px-2 md:mt-8 md:px-0">
          <Button.xl
            children="다운로드"
            disabled={clicked || checkButtonDisable(approveButtonType.DOWNLOAD)}
            onClick={async () => {
              if (ref?.current) {
                setDownload(true);
              }
            }}
            className="filled-green max-md:hidden"
          />
          <Button.xl
            children={fieldtrip?.fieldtripResultStatus === 'RETURNED' ? '반려됨' : '반려'}
            disabled={checkButtonDisable(approveButtonType.RETURN)}
            onClick={() => setDeny(true)}
            className="filled-blue"
          />
          <Button.xl
            children={isApproved ? '승인 후 수정' : '수정'}
            disabled={checkButtonDisable(approveButtonType.EDIT)}
            onClick={() => setReadState(false)}
            className="filled-yellow"
          />
          <Button.xl
            children={nowApprove ? '승인' : isApproved ? '승인 완료' : '승인 대기'}
            disabled={checkButtonDisable(approveButtonType.APPROVE)}
            onClick={() => {
              setOpen(true);
              setAgreeAll(false);
            }}
            className="filled-primary"
          />
        </div>
        <SuperModal modalOpen={download} setModalClose={() => setDownload(false)} width="w-max">
          <div className="px-12 py-6">
            <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
              체험학습 결과보고서를 다운로드 하시겠습니까?
            </div>
            <div className="flex space-x-2">
              <Button.lg
                children="다운로드"
                disabled={clicked}
                onClick={async () => {
                  if (ref?.current) {
                    setClicked(true);
                    const doc: any = new jsPDF('p', 'mm', 'a4');
                    //@ts-ignore
                    const imgData = await extractReactData(ref.current);

                    await doc.addImage(imgData, 'PNG', 0, 0, 210, 297);

                    if (planRef?.current) {
                      const planImgData = await extractReactData(planRef.current);
                      await doc.addPage();
                      await doc.addImage(planImgData, 'PNG', 0, 0, 210, 297);
                    }

                    for (const ref of refTextPageRemain.current) {
                      if (ref) {
                        const paperImgData = await extractReactData(ref);
                        await doc.addPage();
                        await doc.addImage(paperImgData, 'PNG', 0, 0, 210, 297);
                      }
                    }

                    for (const ref of separatePaperRefs.current) {
                      if (ref) {
                        const paperImgData = await extractReactData(ref);
                        await doc.addPage();
                        await doc.addImage(paperImgData, 'PNG', 0, 0, 210, 297);
                      }
                    }
                    await doc.save(
                      `체험학습 결과보고서(${fieldtrip?.student?.name}, ${
                        fieldtrip?.startAt &&
                        fieldtrip?.endAt &&
                        makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)
                      }).pdf`,
                    );
                  }
                  setClicked(false);
                  setDownload(false);
                }}
                className="filled-green w-full"
              />
              <Button.lg
                children="취소"
                onClick={async () => {
                  setClicked(false);
                  setDownload(false);
                }}
                className="filled-gray w-full"
              />
            </div>
          </div>
        </SuperModal>
        <SuperModal modalOpen={deny} setModalClose={() => setDeny(false)} width="w-max">
          <Section className="mt-7">
            <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
              이 학생의 체험학습 결과보고서를 반려하시겠습니까?
            </div>
            <Textarea
              placeholder="반려 이유"
              value={notApprovedReason}
              onChange={(e) => setNotApprovedReason(e.target.value)}
            />
            <Button.xl
              children="반려하기"
              disabled={!notApprovedReason}
              onClick={() => {
                setLoading(true);
                denyFieldtripResult({ id: Number(id), data: { reason: notApprovedReason } });
              }}
              className="filled-primary"
            />
          </Section>
        </SuperModal>

        {/* 삭제요청 버튼이 없기 때문에 아래 SuperModal은 실제로 사용되지는 않음, 코드 베이스 유지를 위해서 삭제는 하지않음 */}
        <SuperModal modalOpen={deleteAppeal} setModalClose={() => setDeleteAppeal(false)} width="w-max">
          <Section className="mt-7">
            <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
              이 체험학습 결과보고서를 삭제하도록 요청하시겠습니까?
            </div>
            <Textarea placeholder="삭제 이유" value={deleteReason} onChange={(e) => setDeleteReason(e.target.value)} />
            <span className="text-sm text-red-400">* 교사가 삭제요청하면 학생 또는 보호자가 삭제할 수 있습니다.</span>
            <Button.xl
              children="삭제 요청하기"
              disabled={!deleteReason}
              onClick={() => {
                setLoading(true);
                deleteAppealFieldtripResult({ id: Number(id), data: { reason: deleteReason } });
              }}
              className="filled-red"
            />
          </Section>
        </SuperModal>
      </div>
    </div>
  );
}
